import { useEffect, useState } from 'react'
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
} from '@mui/material'
import { toast } from 'react-toastify'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import {
  QueryParams,
  useLazyGetCommunicationCompetitionsQuery,
} from 'manage-tritag/services/api/endpoints/competitions'
import { useSendMessageMutation } from 'manage-tritag/services/api/endpoints/communications'
import Spinner from 'manage-tritag/components/loading/spinner'
import { useLazyGetTeamsQuery } from 'manage-tritag/services/api/endpoints/teams'
import {
  FormContent,
  StyledButton,
  StyledButtonDiv,
  StyledGridContainer,
  StyledSelect,
  StyledTextField,
} from './styles'

const CommunicationsForm = () => {
  const [params] = useState<QueryParams>({
    offset: 0,
    limit: 100000,
    search: '',
    currentSeason: true,
    association: false,
  })
  const [compTrigger, compResult] = useLazyGetCommunicationCompetitionsQuery()
  const [teamTrigger, teamResult] = useLazyGetTeamsQuery()
  const [sendMessage, { isLoading }] = useSendMessageMutation()
  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      type: [] as string[],
      segment: '',
      group: [] as string[],
      title: '',
      message: '',
    },
  })

  const watchAllFields = watch()

  useEffect(() => {
    if (watchAllFields.segment) {
      if (watchAllFields.segment === 'competition_participants') {
        compTrigger(params)
      } else if (
        watchAllFields.segment === 'team_owners' ||
        watchAllFields.segment === 'team_participants'
      ) {
        teamTrigger(params)
      }
    }
  }, [compTrigger, teamTrigger, params, watchAllFields.segment])

  const onSubmit: SubmitHandler<any> = async (updateData: any) => {
    if (isLoading) return

    const formData = {
      ...updateData,
    }

    await sendMessage(formData)
      .unwrap()
      .then(() =>
        toast.success(
          `${formData.type
            .map(
              (type: string) =>
                `${type.charAt(0).toUpperCase()}${type.slice(1)}`,
            )
            .join(' / ')} messages sent successfully`,
        ),
      )
      .catch(() =>
        toast.error(
          `${formData.type
            .map(
              (type: string) =>
                `${type.charAt(0).toUpperCase()}${type.slice(1)}`,
            )
            .join(' / ')} messages failed to send`,
        ),
      )
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <StyledGridContainer container justifyContent="space-between">
        <Grid item xs={12} sm={12} md={12}>
          <Typography sx={{ fontWeight: 'bold', color: '#008174' }}>
            Communications
          </Typography>
          <FormContent>
            <FormControl variant="filled" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                Type *
              </InputLabel>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <StyledSelect
                    inputProps={{ tabIndex: 8 }}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    disableUnderline
                    size="small"
                    required
                    multiple
                    renderValue={selected =>
                      (selected as string[])
                        .map(
                          id => `${id.charAt(0).toUpperCase()}${id.slice(1)}`,
                        )
                        .join(', ')
                    }
                    {...field}
                  >
                    <MenuItem value="email">
                      <em>Email</em>
                    </MenuItem>
                    <MenuItem value="push">
                      <em>Push Notification</em>
                    </MenuItem>
                  </StyledSelect>
                )}
              />
            </FormControl>
            <FormControl variant="filled" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                Segment *
              </InputLabel>
              <Controller
                name="segment"
                control={control}
                render={({ field: { onChange, ...rest } }) => (
                  <StyledSelect
                    inputProps={{ tabIndex: 8 }}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    disableUnderline
                    size="small"
                    required
                    onChange={selected => {
                      setValue('group', [])
                      onChange(selected)
                    }}
                    {...rest}
                  >
                    <MenuItem value="all">
                      <em>All</em>
                    </MenuItem>
                    <MenuItem value="all_by_role">
                      <em>All by role</em>
                    </MenuItem>
                    <MenuItem value="competition_participants">
                      <em>Competition participants</em>
                    </MenuItem>
                    <MenuItem value="team_owners">
                      <em>Team owners</em>
                    </MenuItem>
                    <MenuItem value="team_participants">
                      <em>Team participants</em>
                    </MenuItem>
                  </StyledSelect>
                )}
              />
            </FormControl>

            {watchAllFields.segment && watchAllFields.segment !== 'all' && (
              <FormControl variant="filled" sx={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-filled-label">
                  Group *
                </InputLabel>
                <Controller
                  name="group"
                  control={control}
                  shouldUnregister
                  render={({ field }) => (
                    <StyledSelect
                      inputProps={{ tabIndex: 8 }}
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      disableUnderline
                      size="small"
                      required
                      multiple
                      renderValue={ids => {
                        let selectedNames: string[]

                        switch (watchAllFields.segment) {
                          case 'competition_participants':
                            selectedNames = (ids as string[])
                              .map(id =>
                                (compResult.data ?? []).find(
                                  comp => comp._id === id,
                                ),
                              )
                              .filter(comp => comp !== undefined)
                              .map(comp => comp!.name!)
                            break
                          case 'team_owners':
                          case 'team_participants':
                            selectedNames = (ids as string[])
                              .map(id =>
                                (teamResult.data ?? []).find(
                                  team => team._id === id,
                                ),
                              )
                              .filter(team => team !== undefined)
                              .map(team => team!.name!)
                            break
                          default:
                            selectedNames = (ids as string[]).map(
                              id =>
                                `${id.charAt(0).toUpperCase()}${id.slice(1)}`,
                            )
                            break
                        }
                        return selectedNames.join(', ')
                      }}
                      {...field}
                    >
                      {watchAllFields.segment === 'competition_participants' &&
                        compResult &&
                        compResult.data &&
                        compResult.data.map(data => (
                          <MenuItem key={data._id} value={data._id}>
                            <em>{data.name}</em>
                          </MenuItem>
                        ))}
                      {(watchAllFields.segment === 'team_owners' ||
                        watchAllFields.segment === 'team_participants') &&
                        teamResult &&
                        teamResult.data &&
                        teamResult.data.map(data => (
                          <MenuItem key={data._id} value={data._id}>
                            <em>{data.name}</em>
                          </MenuItem>
                        ))}
                      {watchAllFields.segment === 'all_by_role' &&
                        ['player', 'referee', 'coach'].map(data => (
                          <MenuItem key={data} value={data}>
                            <em>{data}</em>
                          </MenuItem>
                        ))}
                    </StyledSelect>
                  )}
                />
              </FormControl>
            )}
            {(!watchAllFields.segment || watchAllFields.segment === 'all') && (
              <Box sx={{ width: '100%' }} />
            )}
          </FormContent>

          <FormContent>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 1 }}
                  id="filled-basic"
                  label="Title"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />
          </FormContent>

          <FormContent>
            <Controller
              name="message"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 1 }}
                  id="filled-basic"
                  label="Message"
                  variant="filled"
                  size="small"
                  multiline
                  rows={3}
                  {...field}
                  required
                />
              )}
            />
          </FormContent>
        </Grid>
      </StyledGridContainer>
      <StyledButtonDiv>
        <div />
        <div>
          <StyledButton
            variant="contained"
            color="primary"
            type="submit"
            sx={{ width: '83px' }}
          >
            {isLoading ? <Spinner size={22} /> : 'SUBMIT'}
          </StyledButton>
        </div>
      </StyledButtonDiv>
    </Box>
  )
}

export default CommunicationsForm
